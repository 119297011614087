import {NgModule} from '@angular/core'
import {DynamicIconComponent} from './dynamic-icon.component'
import {CommonModule} from '@angular/common'

@NgModule({
  declarations: [DynamicIconComponent],
  imports: [CommonModule],
  exports: [DynamicIconComponent],
})
export class DynamicIconModule {}
