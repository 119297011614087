import {Component, Input} from '@angular/core'

@Component({
  selector: 'cft-dynamic-icon',
  templateUrl: './dynamic-icon.component.html',
  host: {ngSkipHydration: 'true'}, //eslint-disable-line @angular-eslint/no-host-metadata-property
})
export class DynamicIconComponent {
  name?: string
  collection?: string

  @Input()
  set icon(icon: {name: string; collection: string} | undefined) {
    this.name = `fa-${icon?.name}`
    this.collection = icon?.collection
  }
}
